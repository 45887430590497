<template>
  <div class="CloudPharmacy">
    <Head :classA="'1'" headBg="2"></Head>
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-no-swiping">
      <swiper-slide>
        <div class="swiperFirstWrap">
          <div class="swiperFirst">
            <h3><strong>致医</strong>云药房</h3>
            <p>
              更好用的药店
              <strong>管理系统</strong>
            </p>
            <div @click="showJoin" class="trialBtn">点击申请试用</div>
          </div>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <div class="mainTop">
      <div class="cloudClinicTop">
        <h3>一站式新零售SaaS解决方案</h3>
        <div class="swiperTop">
          <ul class="tabTitle">
            <li @click="tabId = 0" :class="tabId === 0 ? 'active' : ''">
              <div class="icon icon1"></div>
              <div class="title">
                <h6>智能化经营管理</h6>
                <p>功能全面省时高效</p>
              </div>
            </li>
            <li @click="tabId = 1" :class="tabId === 1 ? 'active' : ''">
              <div class="icon icon2"></div>
              <div class="title">
                <h6>处方合规流转</h6>
                <p>助力药店智慧转型</p>
              </div>
            </li>
            <li @click="tabId = 2" :class="tabId === 2 ? 'active' : ''">
              <div class="icon icon3"></div>
              <div class="title">
                <h6>用户画像提取</h6>
                <p>大数据精准营销</p>
              </div>
            </li>
            <li @click="tabId = 3" :class="tabId === 3 ? 'active' : ''">
              <div class="icon icon4"></div>
              <div class="title">
                <h6>员工协同服务</h6>
                <p>提升运营效率</p>
              </div>
            </li>
          </ul>
          <div class="tabBox">
            <div class="tabCon" v-show="tabId === 0">
              <ul class="tabLeft tab1Left">
                <li>开单收银</li>
                <li>药房管理</li>
                <li>GSP合规流程</li>
                <li>BI报表分析</li>
                <li>经营决策辅助</li>
              </ul>
              <div class="tabRight tab1Right">
                <img
                  src="../../assets/images/cloudPharmacy/tab1.jpg"
                  width="560"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 1">
              <ul class="tabLeft tab2Left">
                <li>电子处方</li>
                <li>视频问诊</li>
                <li>在线审方</li>
                <li>集中审方</li>
              </ul>
              <div class="tabRight tab2Right">
                <img
                  src="../../assets/images/cloudPharmacy/tab2.jpg"
                  width="560"
                />
                <img
                  class="tabbg tab2bg"
                  src="../../assets/images/cloudPharmacy/tab2bg.png"
                  width="244"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 2">
              <ul class="tabLeft tab3Left">
                <li>会员管理</li>
                <li>健康服务</li>
                <li>活动营销</li>
                <li>活动分析</li>
              </ul>
              <div class="tabRight tab3Right">
                <img
                  src="../../assets/images/cloudPharmacy/tab3.jpg"
                  width="560"
                />
                <img
                  class="tabbg tab3bg"
                  src="../../assets/images/cloudPharmacy/tab3bg.png"
                  width="271"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 3">
              <ul class="tabLeft tab4Left">
                <li>员工管理</li>
                <li>绩效管理</li>
                <li>商品管理</li>
                <li>财务管理</li>
              </ul>
              <div class="tabRight tab4Right">
                <img
                  src="../../assets/images/cloudPharmacy/tab4.png"
                  width="560"
                />
                <img
                  class="tabbg tab4bg"
                  src="../../assets/images/cloudPharmacy/tab4bg.png"
                  width="277"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cloudClinicScene">
        <div class="sceneLeft">
          <img
            class="iconBg"
            src="../../assets/images/cloudPharmacy/iconBg.png"
            width="516"
          />
          <img
            :class="{ moveTop: index === 0 }"
            class="iconPos"
            src="../../assets/images/cloudPharmacy/iconPos.png"
            width="101"
          />
          <img
            :class="{ moveTop: index === 1 }"
            class="iconInquiry"
            src="../../assets/images/cloudPharmacy/iconInquiry.png"
            width="101"
          />
          <img
            :class="{ moveTop: index === 2 }"
            class="iconBusiness"
            src="../../assets/images/cloudPharmacy/iconBusiness.png"
            width="94"
          />
        </div>
        <div class="sceneRight">
          <p><strong>更好用的、</strong>智能的、移动互联的</p>
          <p>
            全生态的药店管理综合解决方案
            <span>“致医云药房”</span>
          </p>
          <p>全方位赋能药店采购、管理</p>
          <p>
            销售、服务等
            <strong>业务场景</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="cloudClinicMiddle">
      <div class="cloudClinicMiddleMain">
        <h3>硬件套餐</h3>
        <swiper :options="swiperOption1" ref="mySwiper1">
          <!-- slides -->
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=276glkul3wxll&activity_alias=undefined"
              >
                <h6>药店/药房标准套餐</h6>
                <p>一体式药房工作台T2</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudPharmacy/hwBiaozhuntaocan.jpg"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=2ogiygyvznund&activity_alias=undefined"
              >
                <h6>周边互联智能设备</h6>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudPharmacy/hwZhinengshebei.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=3nj3akzg9uwk9&activity_alias=undefined"
              >
                <h6>药店/药房高端套餐</h6>
                <p>一体式药房工作台T2S</p>
                <div class="hardwareImg">
                  <img
                    src="../../assets/images/cloudPharmacy/hwGaoduantaocan.png"
                    alt
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <!-- Optional controls -->
          <!-- 如果需要导航按钮 -->
          <div
            class="swiper-button-prev swiper-button-prev1"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next1"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="cloudClinicBottomWrap">
      <div class="cloudClinicBottom">
        <h3>他们都在用致医云药房</h3>
        <swiper :options="swiperOption2" ref="mySwiper2">
          <!-- slides -->
          <swiper-slide>
            <div class="clinicWrap">
              <img src="../../assets/images/cloudPharmacy/clinic2.png" alt />
              <p class="clinicTitle">振邦大药房</p>
              <div class="clinicText">
                <h6>振邦大药房</h6>
                <p>
                  振邦药业运用致医云药房的视频问诊及远程审方功能模块，为患者提供便利的线上诊疗服务。
                  通过视频问诊、远程审方获取合法处方，解决执业药师不足的问题，帮助诊所降低药师成本，增强药店的专业服务能力，提升用户满意度。
                </p>
              </div>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="clinicWrap">
              <img src="../../assets/images/cloudPharmacy/clinic3.png" alt />
              <p class="clinicTitle">海洲医药</p>
              <div class="clinicText">
                <h6>海洲医药</h6>
                <p>
                  海洲医药运用致医云药房的进销存管理模式，让员工摆脱繁琐的查药、找药和库存整理的程序，将精力更多地用在顾客身上。
                  同时，近效期药品提醒，库存不足提醒等功能也帮助药店大幅节约成本，形成预警机制。
                </p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="clinicWrap">
              <img src="../../assets/images/cloudPharmacy/clinic1.png" alt />
              <p class="clinicTitle">康力生大药店</p>
              <div class="clinicText">
                <h6>康力生大药店</h6>
                <p>
                  康力生大药店运用致医云药房的大数据存储及分析功能，实现对顾客的精准服务，摆脱以往推出的活动无吸引力的问题。
                  通过致医云药房精准定位客户需求和消费特征的功能，药店制定多维度会员模式，获得更科学、更智能的运营建议。
                </p>
              </div>
            </div>
          </swiper-slide>

          <!-- Optional controls -->
          <!-- 如果需要导航按钮 -->
          <div
            class="swiper-button-prev swiper-button-prev2"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next2"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <div class="main">
      <div class="cloudClinicEffect">
        <div class="effectLeft">
          <h4>致医云药房为您解决药店经营难题</h4>
          <dl>
            <dt>1. 会员信息单一，推出活动无吸引力？</dt>
            <dd>
              致医云药房会员管理通过多维度标签，全面洞悉客户消费特征等，让您的营销转化率数倍提升。
            </dd>
          </dl>
          <dl>
            <dt>2. 软件更新不及时，无法应对GSP检查？</dt>
            <dd>
              致医云药房符合《药品经营质量管理规范》，符合监管要求，一键应对GSP检查。
            </dd>
          </dl>
          <dl>
            <dt>3. 缺少远程处方功能支撑，无法合规售卖处方药？</dt>
            <dd>
              致医云药房电子处方平台提供视频问诊、在线审方等功能，提升药店整体处方药销售业绩。
            </dd>
          </dl>
          <dl>
            <dt>4. 药店库存管理困难，人工进销存更新不及时？</dt>
            <dd>致医云药房一键入库，一步销售，实时更新，流程简便更高效。</dd>
          </dl>
        </div>
        <div class="effectRight">
          <img
            src="../../assets/images/cloudPharmacy/bottomR.png"
            width="551"
          />
        </div>
      </div>
    </div>
    <div class="bottomWrap">
      <p class="title">成为致医云药房官方代理</p>
      <p class="paragraph">
        为零售连锁药店、单体药店（含加盟连锁门店）提供更好用的一站式新零售SaaS解决方案。
      </p>
      <span @click="showJoin1" class="joinBtn">立即加盟</span>
    </div>
    <Foot bgcolor="1"></Foot>
    <JoinBox
      ref="joinBox"
      :companyValid="companyValidVal"
      companyText="医疗机构名称（必填）"
      type="3"
      title="致医云药房申请试用"
    ></JoinBox>
    <JoinBox
      ref="joinBox1"
      companyText="企业名称（选填）"
      type="0"
      title="申请加盟官方代理"
    ></JoinBox>
  </div>
</template>
<style scoped>
@import '../../assets/style/hardware.css';
</style>
<style lang="scss" scoped>
.CloudPharmacy {
  .swiperFirstWrap {
    width: 100%;
    height: 550px;
    background: url('../../assets/images/cloudPharmacy/banner1Bg.png') no-repeat;
    background-size: cover;
    .swiperFirst {
      width: 79.17%;
      height: 100%;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 100px;
      box-sizing: border-box;
      h3 {
        font-size: 36px;
        color: rgba(255, 255, 255, 1);
        line-height: 50px;
        border-bottom: 1px solid #fff;
        strong {
          font-weight: bold;
        }
      }
      p {
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
        line-height: 56px;
        padding: 10px 0 30px 0;
        strong {
          font-weight: bold;
        }
      }
      .trialBtn {
        width: 152px;
        height: 52px;
        line-height: 52px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        background: rgba(95, 236, 190, 1);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .mainTop {
    width: 100%;
    background: #fff;
    .cloudClinicTop {
      width: 79.17%;
      max-width: 1000px;
      margin: 0 auto;
      h3 {
        padding: 60px 0;
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
      }
      .tabTitle {
        display: flex;
        justify-content: space-around;
        width: 100%;
        li {
          box-sizing: border-box;
          cursor: pointer;
          width: 250px;
          padding: 14px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(219, 219, 219, 1);
          margin-right: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            width: 51px;
            height: 51px;
            margin: 0 10px 0 7px;
          }
          .icon1 {
            background: url('../../assets/images/cloudPharmacy/tab1gray.svg')
              no-repeat center;
            background-size: 100%;
          }
          .icon2 {
            background: url('../../assets/images/cloudPharmacy/tab2gray.svg')
              no-repeat center;
            background-size: 100%;
          }
          .icon3 {
            background: url('../../assets/images/cloudPharmacy/tab3gray.svg')
              no-repeat center;
            background-size: 100%;
          }
          .icon4 {
            background: url('../../assets/images/cloudPharmacy/tab4gray.svg')
              no-repeat center;
            background-size: 100%;
          }
          .title {
            h6 {
              font-size: 18px;
              color: #1a1c1f;
              font-weight: bold;
              text-align: left;
            }
            p {
              font-size: 18px;
              color: #6d7278;
              text-align: left;
              padding-top: 10px;
            }
          }
        }
        li.active {
          border: 1px solid #2ae6b4;
          .title {
            h6,
            p {
              color: #2ae6b4;
            }
          }
          .icon1 {
            background: url('../../assets/images/cloudPharmacy/tab1blue.svg')
              no-repeat center;
            background-size: 100%;
          }
          .icon2 {
            background: url('../../assets/images/cloudPharmacy/tab2blue.svg')
              no-repeat center;
            background-size: 100%;
          }
          .icon3 {
            background: url('../../assets/images/cloudPharmacy/tab3blue.svg')
              no-repeat center;
            background-size: 100%;
          }
          .icon4 {
            background: url('../../assets/images/cloudPharmacy/tab4blue.svg')
              no-repeat center;
            background-size: 100%;
          }
        }
        li:nth-child(4) {
          margin-right: 0;
        }
      }
      .tabBox {
        padding: 80px 0;
        .tabCon {
          display: flex;
          .tabLeft {
            width: 37.5%;
            padding-right: 75px;
            padding-top: 30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            li {
              color: #1a1c1f;
              font-size: 20px;
              font-weight: 400;
              line-height: 60px;
              width: 192px;
              height: 60px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0px 0px 10px 0px rgba(232, 232, 232, 0.5);
              border-radius: 4px;
              margin-bottom: 10px;
            }
          }
          .tabRight {
            width: 62.5%;
            text-align: left;
            position: relative;
            z-index: 1;
            img {
              box-shadow: 0 20px 50px 0 rgba(216, 216, 216, 0.7);
            }
            .tabbg {
              position: absolute;
              z-index: 2;
              box-shadow: none;
            }
          }
          .tab2Right {
            .tab2bg {
              right: -2%;
              top: 55px;
            }
          }
          .tab3Right {
            .tab3bg {
              right: -2%;
              top: 85px;
            }
          }
          .tab4Right {
            .tab4bg {
              right: -2%;
              bottom: 23px;
            }
          }
        }
      }
    }
    .cloudClinicScene {
      width: 79.17%;
      max-width: 1140px;
      margin: 0 auto;
      padding-bottom: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @keyframes moveTop {
        0% {
          transform: translateY(0);
        }
        25% {
          transform: translateY(15px);
        }
        50% {
          transform: translateY(0);
        }
        75% {
          transform: translateY(15px);
        }
        100% {
          transform: translateY(0);
        }
      }
      .moveTop {
        animation: moveTop 4s infinite;
      }
      .sceneLeft {
        position: relative;
        .iconBg {
          width: 100%;
        }
        .iconPos {
          position: absolute;
          top: 21%;
          left: 16%;
        }
        .iconInquiry {
          position: absolute;
          top: 66%;
          left: 25%;
        }
        .iconBusiness {
          position: absolute;
          top: 51%;
          left: 65%;
        }
      }
      .sceneRight {
        padding-left: 20px;
        p {
          color: #1a1c1f;
          font-size: 22px;
          line-height: 38px;
          strong {
            font-size: 22px;
            font-weight: bold;
          }
          span {
            font-size: 26px;
            font-weight: bold;
            color: #5fecbe;
          }
        }
      }
    }
  }
  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;

    .cloudClinicEffect {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 90px 0 10px 0;
      h4 {
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
        text-align: left;
        position: relative;
      }
      h4::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 32px;
        height: 4px;
        background: linear-gradient(
          125deg,
          rgba(110, 238, 193, 1) 0%,
          rgba(0, 226, 172, 1) 100%
        );
      }
      dl {
        text-align: left;
        dt {
          font-size: 18px;
          color: #1a1c1f;
          padding: 30px 0 10px 0;
        }
        dd {
          font-size: 14px;
          color: #bbb;
        }
      }
      .effectRight {
        padding-left: 30px;
      }
    }
  }
  .cloudClinicBottomWrap {
    width: 100%;
    background: #fff;
    padding-bottom: 70px;
  }
  .bottomWrap {
    width: 100%;
    height: 300px;
    background: linear-gradient(
      125deg,
      rgba(110, 238, 193, 1) 0%,
      rgba(0, 226, 172, 1) 100%
    );
    text-align: center;
    margin-top: 80px;
    .title {
      font-size: 32px;
      color: #fff;
      line-height: 45px;
      padding: 63px 0 22px 0;
    }
    .paragraph {
      font-size: 20px;
      color: #fff;
      line-height: 28px;
    }
    .joinBtn {
      display: inline-block;
      width: 160px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      color: #00e2ac;
      cursor: pointer;
      margin-top: 43px;
      border-radius: 4px;
      background: #fff;
    }
  }
  @media screen and (max-width: 1366px) {
    .mainTop .cloudClinicScene .sceneLeft {
      width: 50%;
    }
  }
  @media screen and (max-width: 1280px) {
    .mainTop .cloudClinicTop,
    .mainTop .cloudClinicScene,
    .cloudClinicBottom {
      width: 90%;
      max-width: initial;
    }
    .mainTop .cloudClinicTop .tabBox .tabCon .tab2Right .tab2bg,
    .mainTop .cloudClinicTop .tabBox .tabCon .tab3Right .tab3bg,
    .mainTop .cloudClinicTop .tabBox .tabCon .tab4Right .tab4bg {
      right: 80px;
    }
  }
  @media screen and (max-width: 1024px) {
    .main,
    .mainTop .cloudClinicTop,
    .mainTop .cloudClinicScene {
      width: 94%;
    }
    .swiperFirstWrap .swiperFirst {
      width: 94%;
    }
    .mainTop .cloudClinicScene {
      width: 98%;
    }
    .mainTop .cloudClinicScene .sceneLeft {
      .iconPos {
        width: 80px;
      }
      .iconInquiry {
        width: 80px;
      }
      .iconBusiness {
        width: 75px;
      }
    }
    .cloudClinicBottomWrap {
      .cloudClinicBottom {
        width: 94%;
      }
    }
    .mainTop .cloudClinicTop .tabBox .tabCon .tabRight img {
      width: 100%;
    }
    .mainTop .cloudClinicTop .tabBox .tabCon .tabRight img.tabbg {
      width: 50%;
    }
    .mainTop .cloudClinicTop .tabBox .tabCon .tab2Right .tab2bg,
    .mainTop .cloudClinicTop .tabBox .tabCon .tab3Right .tab3bg,
    .mainTop .cloudClinicTop .tabBox .tabCon .tab4Right .tab4bg {
      right: -35px;
    }
    .main .cloudClinicEffect {
      .effectRight {
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mainTop .cloudClinicTop .tabBox .tabCon .tabLeft {
      padding-right: 0;
    }
    .mainTop .cloudClinicTop .tabBox .tabCon .tabRight {
      display: flex;
      align-items: center;
    }
    .mainTop .cloudClinicScene {
      width: 90%;
      margin: 0 auto;
      display: block;
      position: relative;
    }
    .mainTop .cloudClinicScene .sceneRight {
      padding: 40px 0;
    }
    .mainTop .cloudClinicScene .sceneLeft {
      width: 100%;
      .iconPos {
        top: 21%;
        left: 16%;
      }
      .iconInquiry {
        top: 66%;
        left: 25%;
      }
      .iconBusiness {
        top: 51%;
        left: 65%;
      }
    }
    .main .cloudClinicEffect {
      display: block;
    }
    .mainTop .cloudClinicTop .tabBox .tabCon .tab2Right .tab2bg,
    .mainTop .cloudClinicTop .tabBox .tabCon .tab3Right .tab3bg,
    .mainTop .cloudClinicTop .tabBox .tabCon .tab4Right .tab4bg {
      right: -15px;
    }
  }
  @media screen and (max-width: 550px) {
    .main .cloudClinicEffect .effectRight img {
      width: 90%;
    }
    .swiperFirstWrap .swiperFirst {
      padding-left: 20px;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'
import JoinBox from '@/components/common/JoinBox'

export default {
  name: 'home',
  components: {
    Head,
    Foot,
    JoinBox
  },
  data () {
    return {
      companyValidVal: true,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        autoplay: false,
        loop: true
      },
      index: 0, // 当前动画
      tabId: 0,
      swiperOption1: {
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1'
        },
        autoplay: false,
        loop: true,
        spaceBetween: 0,
        slidesPerView: 3, // 开启自定义slide宽度，配合下面css样式设置即可
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination1',
          clickable: true
        }
      },
      swiperOption2: {
        navigation: {
          nextEl: '.swiper-button-next2',
          prevEl: '.swiper-button-prev2'
        },
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination2',
          clickable: true
        }
      }
    }
  },
  methods: {
    pop () {
      const that = this
      for (var i = 0; i < 3; i++) {
        var changeIndexFun = that.changeIndex(i)
        setTimeout(changeIndexFun, i * 4000)
      }
    },
    changeIndex (i) {
      const that = this
      return function () {
        that.index = i
        if (i === 2) {
          setTimeout(that.pop, 4000)
        }
      }
    },
    tab (index) {
      this.num = index
    },
    showJoin () {
      this.$refs.joinBox.isShowDialog = true
    },
    showJoin1 () {
      this.$refs.joinBox1.isShowDialog = true
    }
  },
  computed: {},
  mounted () {
    this.pop()
  }
}
</script>
